import type { Route } from 'nextjs-routes';

export const PAGE_TYPE_DICT: Record<Route['pathname'], string> = {
  '/': 'Homepage',
  '/txs': 'Transactions',
  '/txs/kettle/[hash]': 'Kettle Transactions',
  '/tx/[hash]': 'Transaction Details',
  '/blocks': 'Blocks',
  '/block/[height_or_hash]': 'Block Details',
  '/accounts': 'Top Accounts',
  '/address/[hash]': 'Address Details',
  '/verified-contracts': 'Verified Contracts',
  '/contract-verification': 'Contract Verification',
  '/address/[hash]/contract-verification': 'Contract Verification for Address',
  '/tokens': 'Tokens',
  '/token/[hash]': 'Token Details',
  '/token/[hash]/instance/[id]': 'Token Instance',
  '/apps': 'DApps',
  '/apps/[id]': 'DApp',
  '/stats': 'Stats',
  '/api-docs': 'REST API',
  '/graphiql': 'GraphQL',
  '/search-results': 'Search Results',
  '/auth/profile': 'Profile',
  '/account/watchlist': 'Watchlist',
  '/account/api-key': 'API Keys',
  '/account/custom-abi': 'Custom ABI',
  '/account/public-tags-request': 'Public Tags',
  '/account/tag-address': 'Private Tags',
  '/account/verified-addresses': 'Verified Addresses',
  '/withdrawals': 'Withdrawals',
  '/visualize/sol2uml': 'Solidity UML Diagram',
  '/csv-export': 'Export data to CSV file',
  '/deposits': 'Deposits (L1 > L2)',
  '/output-roots': 'Output Roots',
  '/batches': 'Tx Batches (L2 blocks)',
  '/batches/[number]': 'L2 Tx Batch Details',
  '/ops': 'User Operations',
  '/op/[hash]': 'User Operation Details',
  '/404': '404',
  '/name-domains': 'Domains search and resolve',
  '/name-domains/[name]': 'Domain Details',
  '/validators': 'Validators List',
  '/gas-tracker': 'Gas Tracker',

  // service routes, added only to make typescript happy
  '/login': 'Login',
  '/api/media-type': 'Node API: Media type',
  '/api/proxy': 'Node API: Proxy',
  '/api/csrf': 'Node API: CSRF token',
  '/api/healthz': 'Node API: Health check',
  '/auth/auth0': 'Auth',
  '/auth/unverified-email': 'Unverified Email',
};

export default function getPageType(pathname: Route['pathname']) {
  return PAGE_TYPE_DICT[pathname] || 'Unknown page';
}
